export enum CompanyType {
  Category = 'category',
  Type = 'type',
  Name = 'name',
  Status = 'active'
}

export const CompanyCategoryToType = {
  customer: 'customerType',
  vendor: 'vendorType'
};

export const CompanyTypeOptions = [
  { value: null, text: 'ALL' },
  { value: 'FF', text: 'FF', category: 'customer' },
  { value: 'SSL', text: 'SSL', category: 'customer' },
  { value: 'BCO', text: 'BCO', category: 'customer' },
  { value: 'NGL', text: 'NGL', category: 'customer' },
  { value: 'Service Vendor', text: 'Service Vendor', category: 'vendor' },
  {
    value: 'Non-Service Vendor',
    text: 'Non-Service Vendor',
    category: 'vendor'
  }
];
