
















import { Component, Vue, Prop } from 'vue-property-decorator';
import { ToastHelper } from '@/utils/toast.util';
import { CompanyService } from '@/shared/services/mater-data/company.service';
import { MasterDataService } from '@/shared/services/mater-data/master-data.service';
import { DriverService } from '@/shared/services/mater-data/driver.service';
import { DispatchService } from '@/shared/services/dispatch/dispatch.service';

@Component({})
export default class FileDownload extends Vue {
  @Prop({ default: false }) blockVariant: boolean;
  @Prop({ default: null }) type: string;
  @Prop({ default: null }) category: string;
  @Prop({ default: null }) id: string;

  fileLink: string;

  constructor() {
    super();
  }

  downloadFile() {
    let cb: Promise<any>;

    switch (this.type) {
      case 'driver':
        cb = DriverService.getDriverFileLink(this.id, this.category);
        break;
      case 'employee':
        cb = MasterDataService.getEmployeeFileLink(this.id, this.category);
        break;
      case 'company':
        cb = CompanyService.getCompanyFileLink(this.id, this.category);
        break;
      case 'dispatch':
        cb = DispatchService.getFileLink(this.id, this.category);
        break;
      case 'perdiem':
        console.log(this.id, this.category);
        break;
    }

    cb.then(res => {
      this.fileLink = res.url;
      window.open(this.fileLink);
    }).catch(err => {
      ToastHelper.show('Cant get file link', err.message);
    });
  }
}
