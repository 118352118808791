import {
  required,
  helpers,
  requiredIf,
  maxLength
} from 'vuelidate/lib/validators';
import { coupleMails } from '@/shared/validators/couple-mails';

export const fiveNumbers = helpers.regex('fiveNumbers', /^[0-9]{5}$/);
export const nineNumbers = helpers.regex('nineNumbers', /^[0-9]{9}$/);
export const telNumber = helpers.regex(
  'telNumber',
  /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/
);

export const companyValidation = {
  name: { required },
  address1: { required },
  city: { required },
  zip: { required, fiveNumbers },
  contactName: {},
  email: { coupleMails, maxLength: maxLength(256) },
  officePhone: { telNumber },
  extPhoneNumber: { maxLength: maxLength(5) },
  cellPhone: { telNumber },
  fax: { telNumber },
  accountPhone: { telNumber },
  accountFax: { telNumber },
  accountEmail: { coupleMails, maxLength: maxLength(256) },
  vendorEin: {},
  vendorSsn: {},
  vendorType: {},
  customerType: {},
  country: { required },
  state: { required }
};

export const companyVendorValidation = Object.assign({}, companyValidation, {
  vendorType: { required },
  vendorEin: { required: requiredIf(form => !form.vendorSsn) },
  vendorSsn: { required: requiredIf(form => !form.vendorEin) },
  vendorW9: { required }
});

export const companyCustomerValidation = Object.assign({}, companyValidation, {
  customerType: { required },
  password: { required }
});

export const companyData = {
  name: null,
  customer: false,
  vendor: true,
  customerType: null,
  vendorType: null,
  password: null,
  address1: null,
  address2: null,
  city: null,
  state: 'AL',
  zip: null,
  country: 'United States',
  contactName: null,
  email: null,
  officePhone: null,
  extPhoneNumber: '',
  cellPhone: null,
  fax: null,
  remark: null,
  accountName: null,
  accountPhone: null,
  accountFax: null,
  accountEmail: null,
  creditHold: false,
  customerTerms: 'Prepaid',
  customerCreditLimit: null,
  customerFactoring: false,
  customerApplicationFiled: false,
  vendorTerms: 'Prepaid',
  vendorW9: 'IND/SOLE',
  vendorEin: null,
  vendorSsn: null,
  vendor1099: false,
  tracking: true,
  active: true,
  files: [
    {
      category: 'BANK',
      documentName: null,
      filename: null,
      body: null,
      tempS3FileName: '',
      tempS3PreSignedUrl: ''
    },
    {
      category: 'CUSTOMER',
      documentName: null,
      filename: null,
      body: null,
      tempS3FileName: '',
      tempS3PreSignedUrl: ''
    },
    {
      category: 'VENDOR',
      documentName: null,
      filename: null,
      body: null,
      tempS3FileName: '',
      tempS3PreSignedUrl: ''
    },
    {
      category: 'VOIDCHECK',
      documentName: null,
      filename: null,
      body: null,
      tempS3FileName: '',
      tempS3PreSignedUrl: ''
    }
  ]
};
